import React from "react";
import AboutTabs from "./AboutTabs";

const title = "About Me";
const description = `Hello! I'm Amanda Hill, dental hygienist, speaker, and author. I write for a number of
            industry publications including RDH Magazine, Inside Dental Hygiene, Infection Control Today, Dentistry Today, 
            and Dr. Bicuspid. I also speak from coast to coast on issues that I am passionate about in the world of dental 
            hygiene - from infection control to office communication and inclusivity. Learn more about my work, view my 
            speaker packet, or get in touch below!`;

const videoUrl = 'https://www.youtube.com/embed/LyzcxTf2n0g?si=v297UeE8gmLlS_TN';

const About = (props) => {
  return (
    <div className="about-area about-position-top ptb--60  bg_color--1">
      <div className="about-wrapper">
        <div className="container">
          <div className="row row--35 align-items-center">
            {!videoUrl && (
              <React.Fragment>
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/ahrdh/about-headshot.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>

                <div className="col-lg-7">
                  <div className="about-inner inner pt--100">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <AboutTabs
                        tabStyle="tab-style--1"
                        handleScrollTo={props.handleScrollTo}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            
            {videoUrl && (
              <React.Fragment>
                
              <div className="col-lg-5">
                <div className="video-wrapper">
                  <iframe
                    src={videoUrl}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              {/* <div style={{display: "flex", flexWrap: "wrap", paddingTop: "100px"}}> */}

                <div className="col-lg-7" style={{minWidth: "570px"}}>
                  
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="about-inner inner">
                    <div className="row mt--30">
                      <AboutTabs
                        tabStyle="tab-style--1"
                        handleScrollTo={props.handleScrollTo}
                      />
                    </div>
                  </div>
                </div>
              {/* </div> */}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
