import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { slickDot } from "../../page-demo/script";
import SpeakerPacket from "../pdf/Amanda_Hill_speaker_packet.pdf";
import BiofilmPdf from "../pdf/Biofilm.pdf";
import ChainPdf from "../pdf/Chain.pdf"
import CommunicationPdf from "../pdf/Communication.pdf"
import CuriosityPdf from "../pdf/Curiosity.pdf"
import InstrumentPdf from "../pdf/Instrument.pdf"
import LinesPdf from "../pdf/Lines.pdf"
import PerfectJobPdf from "../pdf/PerfectJob.pdf"
import StellarPdf from "../pdf/Stellar.pdf"
import SuctionPdf from "../pdf/Suction.pdf"
import TransPdf from "../pdf/TransgenderDental.pdf"

const portfolioList = [
  {
    image: "image-1",
    title: "Crashing the Biofilm Party",
    subtitle: "Understanding the path to safe dental unit water lines",
    pdf: BiofilmPdf
  },
  {
    image: "image-2",
    title: "Good Suction Sucks",
    subtitle: "Understanding suction lines for safety and efficiency",
    pdf: SuctionPdf
  },
  {
    image: "image-3",
    title: "Learning Your Lines",
    subtitle:
      "Maintaining dental unit water and suction lines for optimal safety",
    pdf: LinesPdf
  },
  {
    image: "image-4",
    title: "Breaking the Chain",
    subtitle: "Mastering infection control basics",
    pdf: ChainPdf
  },
  {
    image: "image-5",
    title: "From Standard to Stellar",
    subtitle: "Elevating dental care",
    pdf: StellarPdf
  },
  {
    image: "image-6",
    title: "There's an Instrument for That?",
    subtitle: "Conquering tricky spots in dental hygiene",
    pdf: InstrumentPdf
  },
  {
    image: "image-7",
    title: "Curiosity Revived the Cat",
    subtitle: "How a sense of wonder can revive your career",
    pdf: CuriosityPdf
  },
  {
    image: "image-8",
    title: "The Communication Code",
    subtitle: "From team dynamics to case acceptance",
    pdf: CommunicationPdf
  },
  {
    image: "image-9",
    title: "How to Land the Perfect Job",
    subtitle: "Sponsored by DentalPost",
    pdf: PerfectJobPdf
  },
  {
    image: "image-10",
    title: "Transgender Dental Health 101",
    subtitle: "Understanding and supporting your trans patients and families",
    pdf: TransPdf
  },
];

const PresentationPortfolio = () => (
  <div className="portfolio-area ptb--80 bg_color--5">
    <div className="rn-slick-dot">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
              <h3 className="title">Amanda's Speaker Portfolio</h3>
              <p>
                For more information, download my{" "}
                <Link to={SpeakerPacket} target="_blank" rel="noreferrer">
                  speaker packet
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="slick-space-gutter--15 slickdot--20">
              <Slider {...slickDot}>
                {portfolioList.map((value, index) => (
                  <div className="portfolio" key={index}>
                    <div className="thumbnail-inner">
                      <div className={`thumbnail ${value.image}`}></div>
                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <h4>
                          <Link 
                            to={value.pdf}
                            target="_blank"
                            rel="noreferrer">
                              {value.title}</Link>
                        </h4>
                        <h6 style={{ color: "#FFF" }}>{value.subtitle}</h6>
                        {value.pdf && <div className="portfolio-button">
                          <Link
                            to={value.pdf}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <a className="rn-btn">See More</a>
                          </Link>
                        </div>}
                      </div>
                    </div>
                    {value.pdf && <Link className="link-overlay" 
                            to={value.pdf} 
                            target="_blank"
                            rel="noreferrer"></Link>}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PresentationPortfolio;
