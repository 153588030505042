import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/system";
import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";

import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import PageHelmet from "../Helmet";
import ScaleLoader from "react-spinners/ScaleLoader";
import Header from "../HeaderCompact";
import Footer from "../Footer";
import { selectArticles, selectRecentArticles } from "../../store";
import {
  initializeAppContent,
  initializeArticlePreviewData,
} from "../../reducer";

const About = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeAppContent());
    dispatch(initializeArticlePreviewData());
  }, []);

  const postList = useSelector(selectRecentArticles);
  const fullPostList = useSelector(selectArticles);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fullPostList.length) : 0;

  const handleChangePage = (
    event,
    newPage,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Blog" />

      <Header pageTitle="Blog" />

      {/* Start About Area  */}
      <div className="rn-about-area pb--120 pt--160 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 auto-hide">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/ahrdh/blog-headshot.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Blog</h2>
                    <p className="description">
                      Check my most recent articles, or see the full archive
                      below
                    </p>
                  </div>
                </div>
                {!postList && <ScaleLoader />}
                {postList &&
                  postList.map((value, i) => (
                    <div className="article-card" key={i} onClick={() => window.open(value.link,'_blank')}>
                      <div className="preview-img">
                        <img src={value.images ? value.images[0] : ""}></img>
                      </div>
                      <div className="content">
                        <div className="date">{value.date}</div>
                        <div className="title">{value.title}</div>
                        <div className="article-link">
                          <img src={value.favicon} className="thumbnail"></img>
                          <a
                            className="article-url"
                            href={value.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {value.domain}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Start Archive Area */}
      <div className="article-archive ptb--60 bg_color--5">
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-lg-12">
              <div className="section-title text-center ptb--20">
                <h3 className="fontWeight500">Archive</h3>
              </div>
            </div>
            <table>
              <tbody>
                {!fullPostList && <ScaleLoader />}

                {(
                  fullPostList &&
                  fullPostList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                ).map((value, i) => (
                  <tr className="content article-row" onClick={() => window.open(value.link,'_blank')}>
                    <th className="date">{value.date}</th>
                    <th className="title">{value.title}</th>
                    <th className="article-link">
                      <img src={value.favicon} className="thumbnail"></img>
                      <a
                        className="article-url"
                        href={value.link}
                        onClick={event=>{event.stopPropagation()}}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {value.domain}
                      </a>
                    </th>
                  </tr>
                ))}

                {emptyRows > 0 && (
                  <tr style={{ height: 34 * emptyRows }}>
                    <td colSpan={3} aria-hidden />
                  </tr>
                )}
              </tbody>

              <tfoot>
                <tr>
                  <CustomTablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={fullPostList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                        slots: {
                          firstPageIcon: FirstPageRoundedIcon,
                          lastPageIcon: LastPageRoundedIcon,
                          nextPageIcon: ChevronRightRoundedIcon,
                          backPageIcon: ChevronLeftRoundedIcon,
                        },
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      <Footer />
    </React.Fragment>
  );
};
const blue = {
  50: "#F0F7FF",
  200: "#A5D8FF",
  400: "#3399FF",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 4px 0;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 2px 0 2px 4px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 6px; 
    background-color: transparent;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    transition: all 100ms ease;

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
      border-color: ${blue[400]};
    }
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    transition: all 120ms ease;

    > svg {
      font-size: 22px;
    }

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
      border-color: ${blue[400]};
    }

    &:disabled {
      opacity: 0.3;
      &:hover {
        border: 1px solid ${
          theme.palette.mode === "dark" ? grey[800] : grey[200]
        };
        background-color: transparent;
      }
    }
  }
  `
);

export default About;
