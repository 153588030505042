import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useSelector } from "react-redux";
import { selectRecentArticles } from "../../store";

const BlogSection = (props) => {
  const postList = useSelector(selectRecentArticles);

  return (
    <div className="rn-blog-area ptb--80 bg_color--5 mb-dec--30">
      <div className="container">
        <div className="row row--35 align-items-center">
          <div className="col-lg-5 auto-hide">
            <div className="thumbnail">
              <img
                className="w-100"
                src="/assets/ahrdh/blog-headshot.jpg"
                alt="Blog Headshot"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
              <h3>Amanda's Latest Articles</h3>
              <p>Read all my articles <a href="/blog"> here</a>!</p>
            </div>
            {!postList && <ScaleLoader />}
            {postList &&
              postList.map((value, i) => (
                <div className="article-card" key={i} onClick={() => window.open(value.link,'_blank')}>
                  <div className="preview-img">
                    <img
                      src={value.images ? value.images[0] : ""}
                    ></img>
                  </div>
                  <div className="content">
                    <div className="date">{value.date}</div>
                    <div className="title">{value.title}</div>
                    <div className="article-link">
                      <img
                        src={value.favicon}
                        className="thumbnail"
                      ></img>
                      <a
                        className="article-url"
                        href={value.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {value.domain}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            <div className="see-all-wrapper">
              <a
                className="see-all-link"
                href={"/blog"}
              >
                See all...
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
