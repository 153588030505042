// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

import { Provider } from 'react-redux'
import store from './store'

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

import Home from "./ahrdh/pages/Home";
import error404 from "./ahrdh/pages/error404";
import Blog from "./ahrdh/pages/Blog";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <PageScrollTop>
            <Switch>
              <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
              <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Home} />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/blog`}
                component={Blog}
              />
              <Route component={error404} />
            </Switch>
          </PageScrollTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
