import React, { Fragment } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Coordinates from "coordinate-parser";
import ScaleLoader from "react-spinners/ScaleLoader";
import "leaflet/dist/leaflet.css";
import { useSelector } from "react-redux";
import { selectEvents } from "../../store";

const Map = props => {
  const eventList = useSelector(selectEvents) 
  return (
    <Fragment>
      {/* Start Contact Map  */}
      <div className="rn-contact-map-wrapper ptb--50 bg_color--1">
        <div className="container">
          <div
            className="section-title service-style--3 text-left mb--15 mb_sm--0"
            style={{ width: "350px" }}
          >
            <h3>Events</h3>
            <p>Where in the world is Amanda Hill?</p>
          </div>
          {/* Start Single Map  */}

          <div className="row row--35 align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <table
                style={{
                  maxHeight: "550px",
                  overflowY: "auto",
                  display: "block",
                }}
              >
                <tbody>
                  {!eventList && <ScaleLoader />}
                  {eventList &&
                    eventList.map((el, i) => (
                      <tr key={i}>
                        <td width="40%">
                          <div>
                            {el.from_date === el.to_date
                              ? el.from_date
                              : `${el.from_date} - ${el.to_date}`}
                          </div>
                          <b>{el.name}</b>
                          <div
                            style={{
                              color: "#888",
                              fontSize: "0.9em",
                              lineHeight: "1.2",
                            }}
                          >
                            {el.presentation}
                          </div>
                          <div
                            style={{
                              color: "#888",
                              fontSize: "0.9em",
                              lineHeight: "1.2",
                            }}
                          >
                            {el.notes}
                          </div>
                        </td>
                        <td>{el.location}</td>
                        <td width="100px">
                          <a
                            href={el.link}
                            disabled={!el.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              backgroundColor: el.link
                                ? "#DF2882"
                                : "#AAA",
                              borderRadius: "5px",
                              color: "#FFF",
                              padding: "10px",
                            }}
                          >
                            See more
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="rn-contact-map-area position-relative">
                <div style={{ height: "550px", width: "100%" }}>
                  {!eventList && <ScaleLoader />}
                  {eventList && (
                    <MapContainer
                      center={[40, -100]}
                      zoom={3}
                      scrollWheelZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {eventList.map((el, i) => {
                        if (!el.coordinates) {
                          return;
                        }

                        const position = new Coordinates(el.coordinates);
                        return (
                          <Marker
                            key={i}
                            position={[
                              position.getLatitude(),
                              position.getLongitude(),
                            ]}
                          >
                            <Popup>
                              <h3>{el.name}</h3>
                              <em>
                                {el.from_date === el.to_date
                                  ? el.from_date
                                  : `${el.from_date} - ${el.to_date}`}
                              </em>
                              <hr></hr>
                              <p>Featuring:</p>
                              <p>{el.presentation && 'Amanda Hill - '}<em>{el.presentation}</em></p>
                              <p>{el.notes}</p>
                              {el.link && (
                                <a
                                  href={el.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Event info
                                </a>
                              )}
                            </Popup>
                          </Marker>
                        );
                      })}
                    </MapContainer>
                  )}
                </div>
              </div>
            </div>
            {/* End Single Map  */}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Map;
