import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

class AboutTabs extends Component {
  render() {
    let tab1 = "What I Do",
      tab2 = "Awards",
      tab3 = "Experience",
      tab4 = "Education & Certification",
      tab5 = "Professional Associations";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                    <Tab>{tab5}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <b>Speaking</b>I teach a number of continuing
                          education (CE) offerings, both in-person and virtual.
                          <Link
                            onClick={() =>
                              this.props.handleScrollTo("speaker-packet")
                            }
                          >
                            view my speaker packet
                          </Link>
                        </li>
                        <li>
                          <b href="/service">Writing</b>I write about
                          professional skills, industry knowledge, and dental
                          products.
                          <Link
                            onClick={() => this.props.handleScrollTo("blog")}
                          >
                            see my writing
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <b>
                            OSAP Emerging Infection Control Leader{" "}
                            <span>- Winner</span>
                          </b>{" "}
                          June 2022
                        </li>
                        <li>
                          <b>
                            ProEdge <span>- Educator of the Year</span>
                          </b>{" "}
                          Nov. 2021
                        </li>
                        <li>
                          <b>
                            HuFriedy Golden Scaler Award <span>- Winner</span>
                          </b>{" "}
                          May 1996
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <b>
                            Independent Speaker and Author
                            <span> - Amanda Hill, RDH LLC</span>
                          </b>{" "}
                          July 2019 - Present
                        </li>
                        <li>
                          <b>
                            Adjunct Faculty
                            <span> - Virginia Peninsula Community College</span>
                          </b>{" "}
                          Oct. 2023 - Present
                        </li>
                        <li>
                          <b>
                            Monthly Column Contributor
                            <span> - Dentistry IQ</span>
                          </b>{" "}
                          June 2021 - Present
                        </li>
                        <li>
                          <b>
                            Clinical Education Manager
                            <span> - Young Innovations </span>
                          </b>{" "}
                          Oct. 2021 - Dec. 2022
                        </li>
                        <li>
                          <a>View More</a>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <b>
                            Certified in Dental Infection Prevention and Control
                          </b>{" "}
                          June 2023
                        </li>
                        <li>
                          <b>OSAP/DALE Infection Control Certification</b> May
                          2020
                        </li>
                        <li>
                          <b>Registered Dental Hygienist</b> August 1996
                        </li>
                        <li>
                          <b>
                            BS in Dental Hygiene
                            <span> - Old Dominion University</span>
                          </b>{" "}
                          1996
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>American Dental Hygienists’ Association</li>
                        <li>Virginia Dental Hygienists’ Association</li>
                        <li>Tidewater Dental Hygienists’ Association</li>
                        <li>
                          Organization for Safety, Asepsis, and Prevention
                          (OSAP)
                        </li>
                        <li>American Academy of Dental Hygiene</li>
                        <li>DEW Dental Entrepreneurial Woman</li>
                        <li>American Academy for Oral Systemic Health</li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default AboutTabs;
