import React, { Component } from "react";

class ContactOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: "",
      rnEmail: "",
      rnSubject: "",
      rnMessage: "",
    };
  }
  render() {
    return (
      <div className="portfolio-area  bg_color--5 ">
        <div className="contact-form--1">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="section-title text-left mb--50">
                  <h2 className="title">Call Me!</h2>
                  <p className="description">
                    I'm excited to work with you! Give me a call at:{" "}
                    <a href="tel:+7578164542">(757)816-4542</a> or email:
                    <a href="mailto:amandahillrdh@gmail.com">
                      {" "}
                      amandahillrdh@gmail.com
                    </a>{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div
                  className="thumbnail mb_md--30 mb_sm--30"
                  style={{ textAlign: "center" }}
                >
                  <img
                    height="500px"
                    src="/assets/ahrdh/contact.jpg"
                    alt="contact photo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactOne;
