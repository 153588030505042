import { read, utils } from "xlsx";
import { createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  articles: [],
  events: [],
  bio: [],
  articlePreviewData: {},
  status: 'idle',
  error: null
}

const renameCol = (obj, fromField, toField) => {
  obj[toField] = obj[fromField];
  delete obj[fromField];
  return obj;
}

const FROM_DATE_FIELD = "date from (MM/DD/YY)";
const TO_DATE_FIELD = "date to (MM/DD/YY)";
const EVENT_NAME_FIELD = "event name";
const LOCATION_FIELD = "location";
const COORD_FIELD = "coordinates";
const URL_FIELD = "link";
const PRESENTATION_FIELD = "presentation";
const NOTES_FIELD = "notes";
const DATE_FIELD = "date (MM/DD/YY)";
const TITLE_FIELD = "title";

// Use the initialState as a default value
export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case "fetchContent/fulfilled":
      return {
        ...state,
        articles: action.payload.articles,
        events: action.payload.events,
        bio: action.payload.bio
      }
      
    case "fetchArticlePreviewData/fulfilled":
      return {
        ...state,
        articlePreviewData: action.payload
      }
      
    default:
      return state
  }
}

export const initializeAppContent = createAsyncThunk('fetchContent', async () => {
  const contentURL = "./content.xlsx";

  const f = await fetch(contentURL);
  const ab = await f.arrayBuffer();
  const wb = read(ab, { cellDates: true });

  const contentData = {
    articles: parseArticleData(utils.sheet_to_json(wb.Sheets["Articles"])),
    events: parseEventData(utils.sheet_to_json(wb.Sheets["Events"])),
    bio: utils.sheet_to_json(wb.Sheets["Bio"])
  }
  
  return contentData
})

export const initializeArticlePreviewData = createAsyncThunk('fetchArticlePreviewData', async () => {
  const article_metadata_url = "./article_metadata.json";

  const f = await fetch(article_metadata_url);
  const ab = await f.arrayBuffer();
  const wb = JSON.parse(new TextDecoder().decode(ab));
  
  return wb;
})

const parseEventData = (data) => {
  if (!data || !data.length) {
    return undefined;
  }

  let eventList = data;

  eventList = eventList.map((el) => ({
    ...el,
    [FROM_DATE_FIELD]: el[FROM_DATE_FIELD]
      ? `${el[FROM_DATE_FIELD].getMonth() + 1}/${el[
          FROM_DATE_FIELD
        ].getDate()}/${el[FROM_DATE_FIELD].getFullYear()}`
      : "",
    [TO_DATE_FIELD]: el[TO_DATE_FIELD]
      ? `${el[TO_DATE_FIELD].getMonth() + 1}/${el[
          TO_DATE_FIELD
        ].getDate()}/${el[TO_DATE_FIELD].getFullYear()}`
      : "",
  }));

  eventList = eventList.map(element => renameCol(element, FROM_DATE_FIELD, 'from_date'));
  eventList = eventList.map(element => renameCol(element, TO_DATE_FIELD, 'to_date'));
  eventList = eventList.map(element => renameCol(element, EVENT_NAME_FIELD, 'name'));

  return eventList;
};

const parseArticleData = (data) => {
  if (!data || !data.length) {
    return undefined;
  }

  let postList = data;

  postList = postList.map((el) => ({
    ...el,
    [DATE_FIELD]: el[DATE_FIELD]
      ? `${el[DATE_FIELD].getMonth() + 1}/${el[DATE_FIELD].getDate()}/${el[
          DATE_FIELD
        ].getFullYear()}`
      : "",
  }));

  postList = postList.map(element => renameCol(element, DATE_FIELD, 'date'));

  return postList;
};
