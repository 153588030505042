import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducer'

const store = configureStore({
  reducer: rootReducer
})

export const selectRecentArticles = state => {
  const articles = selectArticles(state);

  if (articles.length > 4) {
    return articles.slice(0,4)
  } else {
    return articles
  }
}

export const selectArticles = state => {
  return  state.articles.map(article => ({...article, ...state.articlePreviewData[article.link]}))
}

export const selectEvents = state => {
  return state.events
}

export default store