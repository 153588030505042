import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/amandahillrdh" },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/amanda-hill-rdh-261167149",
  },
  { Social: <FaInstagram />, link: "https://www.instagram.com/amandahillrdh/" },
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.handleScrollTo = event => {
      this.closeMenuTrigger();
      props.handleScrollTo(event);
    };
    this.menuTrigger = this.menuTrigger.bind(this);
    this.closeMenuTrigger = this.closeMenuTrigger.bind(this);
    window.addEventListener('load', function() {
        console.log('All assets are loaded')
    })
  }

  menuTrigger() {
    document.querySelector('.header-wrapper').classList.toggle('menu-open')
  }

  closeMenuTrigger() {
      document.querySelector('.header-wrapper').classList.remove('menu-open')
  }

  render() {
    let logoUrl = <img src="/assets/ahrdh/logo.png" alt="Amanda Hill RDH" />;
    return (
      <header className="header-area header-style-two header--transparent color-black">
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <ul className="mainmenu">
                <li>
                  <Link to="/blog">
                    Blog
                  </Link>
                </li>

                <li>
                  <Link onClick={() => this.handleScrollTo("about")}>
                    About
                  </Link>
                </li>

                <li>
                  <Link onClick={() => this.handleScrollTo("events")}>
                    Events
                  </Link>
                </li>

                <li>
                  <Link onClick={() => this.handleScrollTo("speaker-packet")}>
                    Speaker Packet
                  </Link>
                </li>

                <li>
                  <Link onClick={() => this.handleScrollTo("contact")}>
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header-right">
            <div className="social-share-inner">
              <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a target="_blank" rel="noreferrer" href={`${val.link}`}>
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
