import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/amandahillrdh" },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/amanda-hill-rdh-261167149",
  },
  { Social: <FaInstagram />, link: "https://www.instagram.com/amandahillrdh/" },
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.pageTitle = props.pageTitle;
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  render() {
    let logoUrl = <img src="/assets/ahrdh/logo.png" alt="Amanda Hill RDH" />;
    return (
      <header className="header-area header-style-two header--transparent color-black">
        <div className="header-wrapper header-wrapper-compact">
          <div style={{display: "flex", flexDirection: "column"}}>
            
            <a href="/" className="back-nav" style={{margin: "-5px 0 -15px 0"}}>
              <div style={{display: "flex", alignItems: "center"}}><ChevronLeftRoundedIcon /> Return home</div>
            </a>
            <div className="header-left d-flex align-items-center">
              <div className="logo logo-compact">
                <a href="/">
                  {logoUrl}
                </a>
              </div>
              <h3>{this.pageTitle ? `| ${this.pageTitle}` : ''}</h3>
            </div>
          </div>
          <div className="header-right">
            <div className="social-share-inner">
              <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a target="_blank" rel="noreferrer" href={`${val.link}`}>
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
