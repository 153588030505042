import React, { Fragment, useEffect } from "react";
import  {useDispatch, useSelector } from "react-redux";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../Header";
import Footer from "../Footer";
import Contact from "../sections/Contact";
import Blog from "../sections/Blog";
import Helmet from "../Helmet";
import CounterOne from "../../elements/counters/CounterOne";
import AboutSection from "../sections/About";
import Map from "../sections/Map";
import PresentationPortfolio from "../sections/PresentationPortfolio";
import { initializeAppContent, initializeArticlePreviewData } from "../../reducer";

const SlideList = [
  {
    textPosition: "text-left",
    category: "Speaker | Clinician | Author",
    title: "Welcome! I'm <span>Amanda</span>.",
    description: "BSDH, RDH, CDIPC",
    buttonText: "",
    buttonLink: "",
  },
];

const Home = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initializeAppContent());
    dispatch(initializeArticlePreviewData());
  }, [])

  const handleScrollTo = (target) => {
    setTimeout(() => {
      if (document.getElementById(target)) {
        document
          .getElementById(target)
          .scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);

    return;
  };

  return (
    <Fragment>
      <Helmet pageTitle="Amanda Hill RDH" />

      <Header handleScrollTo={handleScrollTo} />

      {/* Start Slider Area   */}
      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            style={{ backgroundImage: "url(/assets/ahrdh/bg-image-16.jpg)" }}
            className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image"
            key={index}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ""}
                    {value.title ? (
                      <h1
                        className="title"
                        dangerouslySetInnerHTML={{ __html: value.title }}
                      ></h1>
                    ) : (
                      ""
                    )}
                    {value.description ? (
                      <p className="description">{value.description}</p>
                    ) : (
                      ""
                    )}
                    {value.buttonText ? (
                      <div className="slide-btn">
                        <a
                          className="rn-button-style--2 btn-primary-color"
                          href={`${value.buttonLink}`}
                        >
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>
      {/* End Slider Area   */}
      <div id="about"></div>
      <AboutSection
        handleScrollTo={handleScrollTo}
      ></AboutSection>

      <div id="speaker-packet"></div>
      <PresentationPortfolio></PresentationPortfolio>

      {/* Start CounterUp Area */}
      <div className="rn-counterup-area pt--140 p pb--110 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h3 className="fontWeight500">Amanda's Record</h3>
              </div>
            </div>
          </div>
          <CounterOne />
        </div>
      </div>
      {/* End CounterUp Area */}

      <div id="blog"></div>
      <Blog></Blog>

      <div id="events"></div>
      <Map></Map>

      <div id="contact"></div>
      <Contact />

      <Footer />

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </Fragment>
  );
};

export default Home;
